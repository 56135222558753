import React from 'react'

import knowledge from '../../../assets/icons/knowledge.svg'
import klgImg from '../../../assets/images/knowledge.png'
import pdfSvg from '../../../assets/icons/pdf.svg'

import './Grade.css'

export default function Grade() {
  return (
    <div className='grade-container'>
        <div className='grade-title' >考级简介</div>
        <div className='grade-text-con'>
              <div className='grade-text'>CCF 编程能力等级认证(GESP)为青少年计算机和编程学习者提供学业能力验证的规则和平台。GESP覆盖中小学阶段，符合年龄条件的青少年均可参加认证。 图形化编程测试划分为一至四级，考察学生掌握图形化编程的相关知识和操作能力， 熟悉编程各项基础知识和理论框架，通过设定不同等级的考试目标，让学生具备图形化编程从简单的程序到复杂的游戏设计的编程能力，为后期专业化编程学习打下良好基础。</div>
        </div>
        <div>
            <div className='knowledge'>
                <img src={knowledge} width={48} height={48}></img>
                <div>等级知识分布简介</div>
            </div>
        </div>
        <div style={{margin:'12px 48px 12px 48px'}}>
            <img src={klgImg} style={{height:'100%',width:'100%'}}></img>
        </div>
        <div>
             <div className='knowledge'>
                <img src={pdfSvg} width={48} height={48}></img>
                <div>考级说明下载</div>
            </div>
              <a href='https://study-server-vedios.oss-cn-shenzhen.aliyuncs.com/1543469088636960.pdf' className='pdfDownload'>CCF编程能力等级认证概述-图形化编程一至四级</a>
        </div>
    </div>
  )
}
