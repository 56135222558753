import React, { useRef } from 'react'

import { Button, Carousel } from 'antd'
import type { CarouselRef } from 'antd/lib/carousel'

import monkey from '../../assets/images/monkey.png'

import './Python.css'

export default function Python() {

  const codeGame = () => {

  }

  const carouseRef = useRef<CarouselRef>(null)

  const handleBeforeChange = (current:number , next:number) => {
    var currentDiv = document.getElementById(current.toString())
    if(currentDiv){
      currentDiv.style.backgroundColor = '#0e4c60';
    }
    var nextDiv = document.getElementById(next.toString())
    if(nextDiv){
      nextDiv.style.backgroundColor = '#6ae8e3';
    }
  }

  const handleClicked = ( event:any ) => {
    carouseRef.current?.goTo(event.target.id, false)
  }

  return (
    <div className='py-container'>
      <div style={{backgroundColor:'rgb(220,240,240)',height:'24px'}}></div>
      <div style={{display:'flex'}}>
        <div style={{height:'300px',width:'60vw',marginTop:'100px'}}>
          <div style={{ fontSize: '48px', color:'r#0e4c60',marginBottom:'60px',fontWeight:'600'}}>在游戏中体验编程</div>
          <div><Button className='py-bnt' onClick={codeGame}>开始体验</Button></div>
          <div style={{ fontSize: '36px', color: 'r#0e4c60', margin: '60px 60px 0 60px', fontWeight: '500' }}>通过代码控制角色，了解计算机底层逻辑，熟悉计算机语言特点和语法结构</div>
        </div>
        <img src={monkey} style={{height:'300px',width:'300px',marginTop:'160px',marginLeft:'60px'}}></img>
      </div>
      <div style={{width:'100%',marginTop:'120px'}}>
        <div className='hand-text'>
          <div id='0' onClick ={ handleClicked } style={{backgroundColor:'#6ae8e3'}}>场景化学习</div>
          <div id='1' onClick={ handleClicked }>真实代码</div>
          <div id='2' onClick={ handleClicked }>对学生的影响</div>
        </div>
        <Carousel dots={true} autoplay className='carouse'
          beforeChange={ handleBeforeChange }
          ref={carouseRef}>
          <div className='carouse-content'>1</div>
          <div className='carouse-content'>2</div>
          <div className='carouse-content'>3</div>
        </Carousel>
      </div>
    </div>
  )
}
