import axios from "axios";
import { apiDomain } from "./api.ts";

const instance = axios.create({
    baseURL: apiDomain,
    timeout: 5000,
})

const auth = axios.create({
    baseURL:apiDomain,

})

const codeOnline = axios.create({
    baseURL: "https://api.judge.qiuyangli.com"
})

auth.interceptors.request.use(config=>{
    const token = sessionStorage.getItem('token')
    config.headers['Authorization'] = 'Bearer ' + token
    return config
})

auth.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        return Promise.reject(error.response.data)
    }
)

instance.interceptors.request.use(config => {
    return config
    },
    error => {
        return Promise.reject(error.data)
    }
)

instance.interceptors.response.use(
    ( response ) => {
        return response.data
    },
    ( error ) => {
        return Promise.reject(error.response.data)
    }
)

export function login(data){
   return instance.post('/user/login',data)
}

export function scratch_login(){
    return auth.get('/user/login-scratch')
}

export function logout_sys(){
    return auth.get('/user/logout')
}

export function get_scratch_works(){
    return auth.get('/user/scratch-works')
}

export function get_students(){
    return auth.get('/user/students')
}

export function check_login(){
    return auth.get('/user/check-login')
}

export function run_code(data){
    return codeOnline.post('/run_code',data)
}