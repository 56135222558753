
import GoEasy from "goeasy";

let goeasy = GoEasy.getInstance({
    host: "hangzhou.goeasy.io",
    appkey: "BS-a98c1a79e28843a4ab843afb83a348ca",
    modules: ['pubsub']
});
goeasy.connect({
    data: {}
})
let pubsub = goeasy.pubsub

export function get_role(){
    let role = sessionStorage.getItem('role')
    if(role){
        return parseInt(role)
    }
    else{
        return 0
    }
}
export {
    pubsub
}