import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
        <div>
            <div>Copyright @ 智趣编程 2023</div>
            <div>备案号：粤ICP备2022135093号</div>
        </div>
    </div>
  )
}
