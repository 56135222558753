import React, { useEffect, useState } from 'react'
import { Layout, Menu, Avatar } from 'antd'
import type { MenuProps } from 'antd'
import {
    PhoneOutlined,
    UserOutlined,
    SnippetsOutlined,
    ReadOutlined,
    IdcardOutlined,
    TeamOutlined,
    SolutionOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import logo from './assets/logo.jpg';
import './Ucenter.css';
import Compose from '../../components/Compose/Compose';
import { useNavigate, useRoutes, Navigate, useLocation } from 'react-router-dom';
import Uinfo from '../../components/Uinfo/Uinfo';
import Students from '../../components/Students/Students';

const { Sider, Header, Footer, Content } = Layout

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const routes = [
    {
        path:'/edit',
        element: <Uinfo />
    },
    {
        path:'/works/scratch-works',
        element: <Uinfo />
    },
    {
        path:'/works/python-works',
        element: <Uinfo/>
    },
    {
        path:'/works/cplus-works',
        element: <Uinfo/>
    },
    {
        path:'/students',
        element: <Students/>
    },
    {
        path:'/teachers',
        element:<Uinfo/>
    },
    {
        path:'/course',
        element:<Uinfo/>
    },
    {
        path:'*',
        element:<Navigate to='/ucenter/edit'/>
    }
    ]


export default function Ucenter() {
    const { name, role, avatar_url } = useSelector((store:any)=>{
        return store.userInfo
    })
    const items: MenuItem[] = [
        getItem('个人资料', '/ucenter/edit', <IdcardOutlined />),
        getItem('我的作品', '/ucenter/works', <SnippetsOutlined />,[
            getItem('scratch','/ucenter/works/scratch-works'),
            getItem('python','/ucenter/works/python-works'),
            getItem('c/cplus', '/ucenter/works/cplus-works')
        ]),
        getItem('我的课程', '/ucenter/course', <ReadOutlined />),
        (role !== 0 ? getItem('学生管理', '/ucenter/students', <TeamOutlined />) : null),
        (role === 2 ? getItem('教师管理', '/ucenter/teachers', <SolutionOutlined />) : null)
    ]

    const element = useRoutes(routes)
    const navigate = useNavigate()

    const handleMenuClicked = (e:any)=>{
        navigate(e.key)
    }
    
    const [openKeys, setOpenKeys] = useState(Array<string>)

    const handleOpenChange = ( openKeys:any ) => {
        setOpenKeys(openKeys)
    }
    const home_page = () => {
        navigate('/')
    }
    const location = useLocation()
    
    useEffect(()=>{
        const pathname = location.pathname.substring(0,14)
        if (pathname === '/ucenter/works'){
            setOpenKeys(['/ucenter/works'])
        }
    },[])
  return (
    <Layout>
        <Header style={{backgroundColor:'white',display:'flex'}}>
            <div className='logo' onClick={home_page}>
                <img src={logo}></img>
                <div>智趣编程</div>
            </div>
            <div className='right-info'>
              <Compose/>
              <div className='avatar-small'>
                {avatar_url ? <img className='img-con' src={avatar_url}></img> : <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />}
              </div> 
            </div>
        </Header>
        <Layout style={{marginTop:'18px'}}>
            <Sider style={{backgroundColor:'white'}} className='sider'>
                <div className='head-con'>
                    <div className='avatar'>
                          {avatar_url ? <img className='img-con' src={avatar_url}></img> : <Avatar style={{ backgroundColor: '#87d068'}} size={100} icon={<UserOutlined />} />}
                    </div>
                    <div className='name'>{ name }</div>
                </div>
                <Menu
                    mode='inline'
                    items={ items }
                    defaultSelectedKeys={[location.pathname]}
                    defaultOpenKeys={[location.pathname]}
                    onClick={ handleMenuClicked }
                    onOpenChange={ handleOpenChange }
                    openKeys={ openKeys }
                >
                </Menu>
            </Sider>
            <Layout>
                <Content className='content'>
                    { element }
                </Content>
                  <Footer> <div>Copyright © 智趣编程 </div><div>版权所有</div></Footer>
            </Layout>
        </Layout>
    </Layout>
  )
}
