import thunk from 'redux-thunk'
import { userInfoReducer, codeInfo } from './reducer'
import { configureStore } from '@reduxjs/toolkit'

const reducer = {
    userInfo: userInfoReducer,
    codeInfo: codeInfo
}
const middleware = [thunk]
const store = configureStore({
    reducer,
})

export default store