import React from 'react'
import { Carousel, Image } from 'antd'

import a from '../../../assets/material/images/a.png'
import b from '../../../assets/material/images/b.png'
import c from '../../../assets/material/images/c.png'
import d from '../../../assets/material/images/d.png'
import e from '../../../assets/material/images/e.png'
import f from '../../../assets/material/images/f.png'
import './Introduction.css'

import brain from '../../../assets/icons/brain.svg'
import hand from '../../../assets/icons/hand.svg'
import idea from '../../../assets/icons/idea.svg'


export default function Introdution() {
    const images = [f,e,d,c,b,a]
  return (
    <div className='intro-container'>
        <div className='intro-title'>scratch简介</div>
        <div className='intro-text-con'>
            <div className='intro-text'>Scratch 是麻省理工学院开发的一款简易图形化编程工具。这个软件的开发团队称为“终身幼儿园团队”(Lifelong Kindergarten Group)。几乎所有的孩子都会一眼喜欢上这个软件,建立起做编程的欲望。建立程序的过程，用到涂鸦，录音，找图片这些有趣的过程。
            </div>
        </div>
        <div className='intro-img-con'>
            <div className='intro-img-text'>
                <div>
                    <img src={brain} width={48} height={48} style={{marginRight:'12px'}}></img>
                    <div>头脑风暴，新的认识</div>
                </div>
                <div>
                      <img src={idea} width={48} height={48} style={{ marginRight: '12px' }}></img>
                    <div>提高想象力，开创创造力</div>
                </div>
                <div>
                      <img src={hand} width={48} height={48} style={{ marginRight: '12px' }}></img>
                    <div>动手能力，逻辑思维</div>
                </div>
            </div>
            <div className='intro-images'>
                <Carousel autoplay={true}>
                    {images.map((image,index)=>{
                        return (
                            <div key={index}>
                                <Image src={image} height={400}/>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
        <div className='intro-vedios-con'>
            <div>
                  <div className='vedio'>
                      <video style={{ width: '600px' }} src='https://cdn.assets.qiuyangli.com/vedios/a.mp4' controls>
                          <source src='https://cdn.assets.qiuyangli.com/vedios/a.mp4' type='vedio/mp4'></source>
                      </video>
                  </div>
            </div>
            <div>
                <div className='vedio'>
                      <video style={{ width: '600px' }} src='https://cdn.assets.qiuyangli.com/vedios/b.mp4' controls>
                          <source src='https://cdn.assets.qiuyangli.com/vedios/b.mp4' type='vedio/mp4'></source>
                      </video> 
                </div>
            </div>
        </div>
    </div>
  )
}
