import React from 'react'

import xas from '../../assets/images/xas.jpeg'
import jb from '../../assets/icons/jiangbei.svg'
import prove from '../../assets/images/prove.png'
import study from '../../assets/icons/study.svg'
import online from '../../assets/icons/online.svg'
import onlineCoding from '../../assets/images/online-coding.png'


import './CPlus.css'
import { Button } from 'antd'


export default function CPlus() {

  const entryOj = () => {
    //window.open()
  }
  return (
    <div className='csp-con'>
      <div className='csp-header'>
        <div className='csp-title'>信息竞赛学习</div>
        <div className='csp-split-line'></div>
        <div className='csp-dis-text'>培养学生的逻辑思维和计算机编程能力</div>
      </div>
      <div className='options'>
        <div className='l-option'>信奥赛介绍</div>
        <div className='l-option'>学习方式</div>
        <div className='l-option'>在线编程</div>
        <div className='l-option'>
          <Button className='entry' onClick={entryOj}>进入学习</Button></div>
      </div>
      <div className='competition'>
          <div className='competition-title'>
            <img src={jb} style={{height:'36px',width:'36px',margin:'0 12px 0 36px'}}></img>
            信息学奥赛</div>
          <div className='competition-body'>
            <div className='competition-introduction'>
              <div>
                信息学奥赛编程（NOIP）
              </div>
              <div>
              信息学奥赛（全国青少年信息学奥林匹克联赛（NOIP））的训练辅导，是面向小学四年级及以上的中小学生，主要使用C++语言培训。全国青少年信息学奥林匹克联赛由中国计算机学会统一组织，是一项面向全国青少年的信息学竞赛和普及活动，旨在向那些在中学阶段学习的青少年普及计算机科学知识。
              </div>
            </div>
            <img src={xas} className='competition-img'></img>
          </div>
        <div className='prove'>
          <div>竞赛成长之路</div>
          <img src={prove}></img>
        </div>
      </div>
      <div className='study-introduction'>
        <div className='competition-title'>
          <img src={study} style={{height:'36px',width:'36px', margin:'0 12px 0 36px'}}></img>
          学习方式
        </div>
        <div className='oj-box'>
          <div className='oj-item'>
            <div>在线题库</div>
            <div>比赛定制</div>
          </div>
          <div className='oj-item'>
            <div>排行榜单</div>
            <div>判题状态</div>
          </div>
        </div>
      </div>
      <div>
        <div className='competition-title'>
          <img src={online} style={{ height: '36px', width: '36px', margin: '0 12px 0 36px' }}></img>
          在线编程
        </div>
        <img src={onlineCoding} style={{width:'80vw', height:'auto',marginTop:'24px'}}></img>
      </div>
    </div>
  )
}
