import React from 'react'
import { Route, Routes,  } from 'react-router-dom'
import Public from '../pages/Public/Public'
import Login from '../pages/Login/Login'
import Register from '../pages/Register/Register'
import Ucenter from '../pages/ucenter/Ucenter'

export default function Routers() {
  return (
    <Routes>
      <Route path='/*' element={<Public/>}></Route>
      <Route path='/login' element={<Login/>}></Route>
      <Route path='/register' element={<Register/>} />
      <Route path='/ucenter/*' element={ <Ucenter/> }></Route>
    </Routes>
  )
}
