import { createSlice } from "@reduxjs/toolkit";
import { get_role } from "../servers/utils";


const userInfoSlice = createSlice({
    name:'userInfo',
    initialState:{
        name: sessionStorage.getItem('name')||'',
        avatar_url: sessionStorage.getItem('avatar_url')||'',
        role: get_role()
    },
    reducers:{
        set_name(state, action){
            state.name = action.payload
        },
        set_role(state, action){
            state.role = action.payload
        },
        set_avatar(state, action){
            state.avatar_url = action.payload
        }
    }
})

const codeSlice = createSlice({
    name:'codeInfo',
    initialState:{
        lang:{mode:'c_cpp',value:'C++'},
        code: "#include <iostream>\nusing namespace std; \n\nint main() \n{ \n   cout << \"Hello World\";\n   return 0;\n}",
        result:'',
    },
    reducers:{
        set_lang(state, action){
            state.lang = action.payload
        },
        set_code(state, action){
            state.code = action.payload
        },
        set_result(state, action){
            state.result = action.payload
        }
    }
})

const userInfoReducer = userInfoSlice.reducer
const { set_name, set_avatar, set_role } = userInfoSlice.actions

const codeInfo = codeSlice.reducer
const { set_code, set_lang, set_result } = codeSlice.actions

export{
    userInfoReducer,
    codeInfo,
    set_name,
    set_role,
    set_avatar,
    set_code,
    set_lang,
    set_result,
}