import React from 'react'
import { Form, Input, Checkbox, Button, message, Modal } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { set_avatar, set_name, set_role } from '../../redux/reducer'
import { pubsub } from '../../servers/utils'

import { login } from '../../servers/servers'

import study from '../../assets/images/study.jpeg'
import back from '../../assets/icons/back.svg'

import './Login.css'

export default function Login() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onFinish = ( value: any ) => {
        const data = {
            'phone':value.phone,
            'password':value.password
        }
        login(data).then((res:any)=>{
            sessionStorage.setItem('token',res.token)
            sessionStorage.setItem('name',res.name)
            sessionStorage.setItem('session_id',res.session_id)
            sessionStorage.setItem('role', res.role)
            dispatch(set_role(res.role))
            dispatch(set_avatar(res.avatar_url))
            dispatch(set_name(res.name))
            pubsub.subscribe({
                channel: res.session_id,
                onMessage: (msg) => {
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('name')
                    var session_id = sessionStorage.getItem('session_id')
                    if(session_id){
                    pubsub.unsubscribe({
                        channel:session_id,
                        onSuccess:()=>{},
                        onFailed:()=>{}
                    })
                    dispatch(set_name(''))
                    Modal.error({
                        title:'重新登录强制下线',
                        content:'您已经在其他地方登录，此页面登录态失效！'
                    })
                    }
                },
                onSuccess:()=>{
                    
                },
                onFailed:(error)=>{
                    console.log(error)
                }
            })
            message.info('登录成功！')
            navigate(-1)
        }).catch(err=>{
            return (
                message.error(err.msg)
            )
        })
    }

    const register = () =>{
        navigate('/register')
    }

    const forgetPassword = () =>{
        console.log('forget')
        //navigate('/forget-password')
    }

    const reback = () =>{
        navigate('/scratch')
    }

  return (
    <div className='container'>
        <img src={back} className='back-img' onClick={reback}></img>
        <div className='form-container'>
            <div className='login-txt'>登录</div>
            <div className='form-site'>
                <Form
                        style={{ maxWidth: 240, margin:'24px' }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        >
                        <Form.Item
                            name='phone' 
                            rules={[{ required: true, message: '请输入账号!' }]}
                            
                            >
                            <Input prefix={<UserOutlined className='site-form-item-icon'/>} placeholder='账号'/>
                            </Form.Item>
                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: '请输入密码!' }]}

                        >
                            <Input prefix={<LockOutlined className='site-form-item-icon'/>} type='password' placeholder='密码' />
                        </Form.Item>

                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>记住密码</Checkbox>
                            </Form.Item>

                            <a className="login-form-forgot"  onClick={ forgetPassword }>
                                忘记密码？
                            </a>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" style={{width:'240px'}}>
                                登录
                            </Button>
                        </Form.Item>
                </Form>
            </div>
        </div>
        <div className='left-site'></div>
        <div className='right-site'>
            <img src={study} style={{height:'200px',width:'200px',marginTop:'60px'}}/>
            <div style={{color:'grey',opacity:0.8,margin:'12px'}}>还没有账号？</div>
            <Button onClick={register} style={{margin:'8px',width:'72px'}}>注册</Button>
        </div>
    </div>
  )
}
