import React from 'react';
import './App.css';

import Routers from './routers/Routers';
import { useDispatch } from 'react-redux';
import { message } from 'antd'

import { check_login } from './servers/servers';
import { set_name } from './redux/reducer';
import { useNavigate } from 'react-router-dom';

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  window.onfocus = () => {
    if (sessionStorage.getItem('token')) {
      check_login().catch(() => {
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('role')
        sessionStorage.removeItem('session_id')
        dispatch(set_name(''))
        navigate('/login')
        message.info('登录过期,请重新登录！')
      })
    }
  }
  return (
    <div className="App">
      <Routers/>
    </div>
  );
}

export default App;
