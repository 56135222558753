import React from 'react'
import Introdution from './Introduction/Introduction'
import Grade from './Grade/Grade'

import imgBg from '../../assets/images/imgBg2.png'
import Footer from '../Footer/Footer'
import scratchLogo from '../../assets/logo/scratch-logo.svg'

export default function Scratch() {
  return (
    <div>
      <img src={imgBg} style={{height:'auto',width:'100vw'}} alt='es-lint want to get'></img>
      <Introdution></Introdution>
      <Grade />
      <div style={{backgroundColor:'#fafadc'}}>
        <img src={scratchLogo} style={{ height:'60px'}}></img>
      </div>
    </div>
  )
}
