import { Table, Space, Tag, Button, message, Upload, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/es/table';
import { InboxOutlined } from '@ant-design/icons';

import { get_students } from '../../servers/servers';
import { apiDomain } from '../../servers/api';

interface DataType {
    key: number;
    name: string;
    gender: string;
    phone: number;
    tags: string[];
}

const { Dragger } = Upload

const columns: ColumnsType<DataType> = [
    {
        title: '序号',
        dataIndex: 'key',
        rowScope: 'row',
    },
    {
        title: '姓名',
        dataIndex: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: '性别',
        dataIndex: 'gender',
    },
    {
        title: '手机',
        dataIndex: 'phone',
    },
    {
        title:'标签',
        dataIndex:'tags',
        render: (tags: string[]) => (
            <span>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === '已过期') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </span>
        ),
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <a>查看</a>
            </Space>
        ),
    },
];

export default function Students() {
    const [open, setOpen] = useState(false)
    const export_list = ()=>{
        console.log('l')
    }

    const handleClick = () =>{
        setOpen(true)
    }
    const before_upload = ( file:any ) => {
        const isEXCEL = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isEXCEL) {
            message.error(`${file.name}不是excel文件!`);
        }
        return isEXCEL || Upload.LIST_IGNORE;
    }
    const handleOk = () =>{
        setOpen(false)
    }
    const [data, setData] = useState([])
    useEffect(()=>{
        get_students().then((res:any)=>{
            const students = res.students
            const data = students.map((item:any,index:number)=>{
                var temp:DataType={
                    key:index,
                    name:item.name,
                    gender:item.gender?'女':'男',
                    phone:item.phone,
                    tags:['学生']
                }
                return temp
            })
        setData(data)
        })
    },[])
  return (
    <div>
        <div style={{float:'right',margin:'8px 8px 8px',display:'flex'}}><Button onClick={handleClick}>导入名单</Button><Button onClick={export_list}>导出名单</Button></div>
        <Table columns={columns} dataSource={data} pagination={{pageSize:8}} bordered></Table>
          <Modal 
          title={'名单导入'} 
          okText='确认' 
          cancelText={'取消'} 
          open={open} 
          onCancel={handleOk} 
          onOk={handleOk}>
            <div style={{display:'flex',margin:'8px'}}>
                <div style={{color:'red'}}>*</div>
                <div>上传示例:<a>点击下载示例</a></div>
            </div>
            <Dragger 
            maxCount={1} 
            beforeUpload={before_upload} 
            action={ apiDomain + '/user/upload-name-list' } 
            headers={{authorization:'Bearer ' + sessionStorage.getItem('token')}}>
                <div style={{fontSize:48,color:'blue'}}><InboxOutlined /></div>
                <p style={{fontSize:18}}>点击上传.xlsx或者.xls文件,一次只能上传一个文件</p>
            </Dragger>
        </Modal>
    </div>
  )
}
