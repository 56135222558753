import React, { useEffect, useRef, useState } from 'react'
import 'xterm/css/xterm.css'
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/theme-clouds';
import 'ace-builds/src-noconflict/ext-language_tools'; 

import { Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { set_code, set_lang, set_result } from '../../redux/reducer';

import plane from '../../assets/icons/plane.svg'
import './CodeOnline.css'
import { codeTemp } from './codeTemp';
import { run_code } from '../../servers/servers';

export default function CodeOnline() {

    const { code, lang, result } = useSelector((store:any)=>{
        return store.codeInfo
    })

    const dispath = useDispatch()

    const onCodeChange = (code:any) => {
        dispath(set_code(code))
    }

    const onModeChange = (mode:string) => {
        var lang
        switch(mode){
            case 'C':
                lang = {mode:'c_cpp',value:mode}
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'C++':
                lang = { mode: 'c_cpp', value: mode }
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'Python2':
                lang = {mode:'python',value:mode}
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'Python3':
                lang = { mode: 'python', value: mode }
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'Golang':
                lang = { mode: 'golang', value: mode }
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'Java':
                lang = { mode: 'java', value: mode }
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
            case 'JavaScript':
                lang = { mode: 'javascript', value: mode }
                dispath(set_lang(lang))
                dispath(set_code(codeTemp[mode]))
                break
        }
    }

    const runCode = () => {
        const data = {src:code,language:lang.value}
        run_code(data).then(res=>{
            dispath(set_result(res.data))
        }).catch(err=>{
            
        })
    }
  return (
    <div className='code-container'>
        <div className='header'>
            <div>选择语言:<Select
                  style={{ width: 140, textAlign:'left' }}
                  onChange={onModeChange}
                  options={[
                      { value: 'C', label: 'C' },
                      { value: 'C++', label: 'C++' },
                      { value: 'Python2', label: 'Python2' },
                      { value: 'Python3', label: 'Python3' },
                      { value: 'Java', label: 'Java', },
                      { value: 'JavaScript', label: 'JavaScript', },
                      { value: 'Golang', label: 'Golang', },
                  ]} 
                  defaultValue={lang.value}
                  />
            </div>
            <div>
                  <Button style={{ backgroundColor:'#325d88',display:'flex', color:'white'}} onClick={runCode}><img src={plane} style={{height:'24px',width:'24px'}}></img>点击运行</Button>
            </div>
        </div>
        <div className='code-area'>
            <AceEditor 
                mode={lang.mode}
                theme="clouds"
                fontSize={20}
                showPrintMargin
                value={code}
                wrapEnabled
                height='100%'
                width='60%'
                className='editor'
                highlightActiveLine
                onChange={onCodeChange} 
                setOptions={{
                    enableBasicAutocompletion: true, 
                    enableLiveAutocompletion: true,   
                    enableSnippets: false, 
                    showLineNumbers: true,
                    useWorker: false
                }}
            />
            <textarea
                className='text-area'
                readOnly
                draggable={false}
                value={result}
            ></textarea>
        </div>
    </div>
  )
}
