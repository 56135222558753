import React, { useState } from 'react'
import { Modal } from 'antd'
import { useNavigate} from 'react-router-dom'

export default function Register() {
    const navigate = useNavigate()
    const handleCancel = () =>{
        navigate('/login')
    }
    const handleOk = () => {
        navigate('/login')
    }
  return (
   <div>
        <Modal
                title="注册提示"
                open={true}
                onCancel={handleCancel}
                onOk={handleOk}
                okText="确认"
                cancelText="取消"
        >
            <div>暂时不支持自主注册功能</div>
        </Modal>
    </div>
  )
}
