import React from 'react'
import { Layout, Menu,  Button, message } from 'antd'
import type { MenuProps } from 'antd';
import { Navigate, useLocation, useNavigate, useRoutes, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './Public.css'
import logo from '../../assets/logo/logo.png'
import Scratch from '../../components/Scratch/Scratch';
import Python from '../../components/Python/Python';
import CPlus from '../../components/CPlus/CPlus';
import Logined from '../../components/Logined/Logined';
import { logout_sys } from '../../servers/servers';
import {  set_name, } from '../../redux/reducer';
import { pubsub } from '../../servers/utils';
import Compose from '../../components/Compose/Compose';
import CodeOnline from '../../components/CodeOnline/CodeOnline';
import Footer from '../../components/Footer/Footer';
 
const { Header, Content } = Layout

type MenuItem = Required<MenuProps>['items'][number];


function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items:MenuItem[] = [
    getItem((<div className='scratch'>scratch</div>),'/scratch','',),
    getItem((<div className='python'>python</div>), '/python', '', ),
    getItem((<div className='cplus'>c/c++</div>), '/cplus', '', ),
    getItem((<div className='code-online'>在线编程</div>), '/code-online', '', )
]


const routes = [
    {
        path:'/scratch',
        element:<Scratch/>,
    },
    {
        path:'/python',
        element:<Python/>
    },
    {
        path:'/cplus',
        element:<CPlus/>
    },
    {
        path:'/code-online',
        element: <CodeOnline/>
    },
    {
        path:'*',
        element:<Navigate to='/scratch'/>
    }
]

export default function Public() {

    const element = useRoutes(routes)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const location = useLocation()

    const onClick:MenuProps['onClick'] = (item:any) =>{
        navigate(item.key)
    }


    function login(){
        navigate('/login')
    }

    const { name } = useSelector((store:any)=>{
        return store.userInfo
    })

    const logout = () => {
        logout_sys().then(()=>{
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('name')
            sessionStorage.removeItem('role')
            sessionStorage.removeItem('avatar_url')
            dispatch(set_name(''))
            let session_id = sessionStorage.getItem('session_id')
            if( session_id ){
                pubsub.unsubscribe({channel:session_id,
                    onSuccess: function () {
                    },
                    onFailed: function (error) {
                    }
})
            }
            message.info('退出成功！')
        })
    }

  return (
    <div>
        <Layout>
            <Header style={{backgroundColor:'white',display:'flex',alignItems:'center'}}>
                <div style={{alignItems:'center',height:'100%',width:'auto',display:'flex',fontSize:'20px',marginRight:'32px', overflow:"hidden",textOverflow:'ellipsis'}}>
                    <img src={logo} style={{height:'48px',width:'48px'}} />
                    智趣编程
                </div>
                <Menu theme='light' 
                    mode='horizontal' 
                    onClick={onClick}
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                    items={items}
                    className='menu-item'
                ></Menu>
                <Compose/>
                {name ? <Logined logout={logout} name={name}/>:<Button onClick={login}>登录</Button>}
            </Header>
            <Content>
                { element }
            </Content>
            <Footer></Footer>
        </Layout>
    </div>
  )
}
