import React, { useState } from 'react'
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom'


const items: MenuProps['items'] = [
    {
        label:'个人中心',
        key:'ucenter',
        icon: <UserOutlined/>
    },
    {
        label:'退出登录',
        key:'logout',
        icon: <LogoutOutlined/>
    }
]

export default function Logined(props:any) {
    const navigate = useNavigate()
    const clicked = (e:any) => {
        switch(e.key){
            case "ucenter":
                navigate('/ucenter')
                break
            case "logout":
                props.logout()
                break
            default:
                break
        }
    }
  return (
    <div>
          <Dropdown menu={{ items, onClick:clicked }}>
              <a onClick={(e) => e.preventDefault()}>
                  <Space>
                      你好!{props.name}
                      <DownOutlined />
                  </Space>
              </a>
          </Dropdown>
    </div>
  )
}
