import React from 'react'
import type { MenuProps } from 'antd';
import { message, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { scratch_login } from '../../servers/servers';
import { scratchDomain } from '../../servers/api';

const dropDownItems: MenuProps['items'] = [
    {
        key: 'scratch',
        label: 'scratch'
    },
    {
        key: 'python',
        label: 'python'
    }
]

export default function Compose() {

    const compose: MenuProps['onClick'] = (item: any) => {
        switch (item.key) {
            case 'scratch':
                const token = sessionStorage.getItem('token')
                if (token) {
                    scratch_login().then(() => {
                        let scratch = window.open(scratchDomain, 'scratch')
                        const data = {
                            token,
                            name: sessionStorage.getItem('name')
                        }
                        window.addEventListener('message', (e) => {
                            if (e.origin !== scratchDomain) {
                                return
                            }
                            else {
                                scratch?.postMessage(JSON.stringify(data), scratchDomain)
                                window.removeEventListener('message', () => { })
                            }
                        })
                    }).catch(() => {
                        message.error('您不是会员，无法使用！')
                    })
                }
                else {
                    message.info('您尚未登录，请先登录！')
                }
                break
            case 'python':
                console.log('aas')
                break
            case 'cplus':
                console.log('cplus')
                break
            default:
                console.log('hhh')
        }
    }
  return (
      <Dropdown
          menu={{ items: dropDownItems, onClick: compose }}
      >
          <Space
              className='dropdown'>
              立即创作
              <DownOutlined />
          </Space>
      </Dropdown>
  )
}
