import { Button, Input, Radio } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import './Uinfo.css'

export default function Uinfo() {
    const { name, phone } = useSelector((store:any)=>{
        return store.userInfo
    })

    const onChange = (value:any) =>{
        console.log(value)
    }
  return (
    <div className='uinfo-con'>
        <div className='input-item'>
            姓名：<Input value={name}></Input>
        </div>
        <div className='input-item'>
            电话：<Input value={phone}/>
        </div>
        <div className='input-item'>
            密码：<Input value={'********'}/>
            <Button>设置密码</Button>
        </div>
        <div className='input-item'>
            性别：<Radio.Group onChange={onChange}>
                <Radio value={0}>男</Radio>
                <Radio value={1}>女</Radio>
            </Radio.Group>
        </div>
        <div style={{display:'flex'}}>
            <Button className='save-btn' type='primary'>保存资料</Button>
        </div>
    </div>
  )
}
