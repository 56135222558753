var apiDomain:string 
var scratchDomain:string

if(process.env.NODE_ENV === 'production'){
    apiDomain = 'https://api.scratch.qiuyangli.com/api'
    scratchDomain = 'https://scratch.qiuyangli.com'
}
else{
    apiDomain = 'http://127.0.0.1:3000/api'
    scratchDomain = 'http://127.0.0.1:8601'
}

export{
    apiDomain,
    scratchDomain
}